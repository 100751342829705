body {
  font-family: 'Times New Roman', serif;
  line-height: 1.4;
  color: #000;
  background-color: #fff;
  margin: 0;
  padding: 0;
}

.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 20px 10px 20px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left, .header-center, .header-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 150px;
}

.header-left {
  align-items: flex-start;
}

.header-center {
  text-align: center;
}

.header-right {
  align-items: flex-end;
}

.profile-image {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.header-center h1 {
  margin: 0 0 5px 0;
  font-size: 24px;
  font-weight: bold;
  font-family: inherit;
  white-space: nowrap;
}

.header-divider {
  width: 80%;
  max-width: 200px;
  margin: 5px auto;
  border: none;
  border-top: 1px solid #000;
}

.header-subtitle {
  font-size: 18px;
  margin: 5px 0 0 0;
  font-family: inherit;
}

.social-links-container {
  display: inline-block;
  text-align: left;
}

.social-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.social-links a,
.social-links .social-link {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: #000;
  font-size: 14px;
}

.social-links a {
  text-decoration: none;
}

.social-links a:hover {
  text-decoration: none;
}

.social-links a:hover .link-text {
  text-decoration: underline;
}

.social-links i {
  margin-right: 5px;
  font-size: 18px;
}

p {
  margin: 5px 0;
  font-size: 16px;
}

.social-icons {
  margin-top: 15px;
}

.social-icons a {
  color: #000;
  font-size: 18px;
  margin: 0 10px;
  text-decoration: none;
  display: inline-block;
  padding: 5px;
  border: 1px solid #000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}

.social-icons a:hover {
  background-color: #f0f0f0;
}

section {
  margin-bottom: 20px;
}

h2 {
  font-size: 18px;
  border-bottom: 1px solid #000;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.internal_entry {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.entry {
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.big_entry {
  margin-bottom: 15px;
  align-items: center;
  flex-wrap: nowrap;
}

.entry-logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.entry-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.entry-title {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.entry-subtitle {
  margin: 1px 0;
  font-size: 14px;
}

.entry-description {
  margin: 2px 0;
  text-align: justify;
  font-size: 14px;
  margin-right: 10px;
}

.entry-date {
  text-align: right;
  font-size: 14px;
  margin-right: 10px;

  white-space: nowrap;
  align-self: flex-start;
  padding-left: 10px;
}
.project-url {
  margin: 1px 0;
  text-align: left;
  font-size: 14px;
  margin-right: 10px;
}
a {
  color: #000;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
.global_entry {
  display: flex;
  align-items: center;
  margin-bottom: 20px; /* Espacio entre las entradas */
}


.entry-data {
  flex-grow: 1; /* Ocupa todo el espacio restante */
}

@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: center;
  }

  .header-left, .header-center, .header-right {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    height: auto;
    align-items: center;
  }

  .social-links {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .social-links a,
  .social-links .social-link {
    margin: 0 10px;
    font-size: 24px;
  }

  .social-links i {
    font-size: 24px;
  }

  .social-links a span,
  .social-links .social-link span {
    display: none;
  }

  .social-links .social-link {
    display: none;
  }

  .entry {
    flex-wrap: nowrap;
  }
}

@media (max-width: 600px) {
  .App {
    padding: 20px 10px 0 10px;
  }

  .entry {
    flex-wrap: nowrap;
  }

  .entry-logo {
    margin-bottom: 5px;
  }

  .entry-date {
    text-align: left;
  }
}